import { Link } from 'preact-router';
import { useEffect, useRef, useState } from 'preact/hooks';
import { searchPosts } from '../../api';
import style from './menu.css';

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Menu = ({ isMenuVisible, hideMenu }) => {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const wrapperRef = useRef(null);
  let timeout;

  useOutsideAlerter(wrapperRef, hideMenu, isMenuVisible);

  const setEscEvent = (e) => {
    if (e.code === 'Escape') {
      hideMenu();
    }
  }

  useEffect(() => {
    document.body.addEventListener('keyup', setEscEvent);
    const element = document.getElementById('search');

    if (element) {
      element.focus();
    }

    return () => {
      document.body.removeEventListener('keyup', setEscEvent);
    }
  }, []);

  useEffect(() => {
    if (query) {
      timeout = setTimeout(() => {
        searchPosts(query)
          .then(setSearchResults);
      }, 500)
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [query]);

  useEffect(() => {
    const element = document.getElementById('search');

    if (isMenuVisible && element) {
      element.focus();
    }
  }, [isMenuVisible]);

  if (!isMenuVisible) return null;

  const links = [
    {
      href: '/add',
      text: '➕ Создать пост',
    },
    {
      href: '/',
      text: '📑 Все посты',
    },
    {
      href: '/links',
      text: '🔗 Короткие ссылки',
    },
    {
      href: '/banners',
      text: '🌉 Баннеры',
    },
  ];

  return (
    <div class={style.menu} ref={wrapperRef}>
      <input
        placeholder='Поиск по постам'
        class={style.spotlight}
        type="search"
        id="search"
        value={query}
        onChange={(e => setQuery(e.target.value))}
        required
        autocomplete="off"
      />
      <div class={style.results}>
        <ul class={style.links}>
          {query
            ? (
              searchResults.length ? (
                searchResults.map(result => (
                  <li>
                    <Link key={result.uniqid} href={`/post/${result.uniqid}`} onClick={hideMenu}>{result.title}</Link>
                  </li>
                ))
              ) : (
                'Ничего не найдено'
              )
            )
            : links.map(link => (
              <li>
                <Link key={link.href} href={link.href} onClick={hideMenu}>{link.text}</Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
};

export default Menu;
