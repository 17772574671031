import { Router } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import { Toaster } from 'react-hot-toast';
import { Store } from "preact-store";

import Login from '../routes/login';
import Home from '../routes/home';
import EditPost from '../routes/post/edit';
import AddPost from '../routes/post/add';
import Links from '../routes/links';
import Banners from '../routes/banners';
import Menu from './menu';

const App = () => {
	const [isMenuVisible, setIsMenuVisible] = useState(false);
	const spotlightEvent = (e) => {
		if (e.ctrlKey) {
			switch (e.code) {
				case 'KeyJ':
					setIsMenuVisible(true);
					break;
			}
		}
	}

	useEffect(() => {
		document.body.addEventListener('keyup', spotlightEvent);
		window.showMenu = () => setIsMenuVisible(true);

		return () => {
			document.body.removeEventListener('keyup', spotlightEvent);
			delete window.showMenu;
		}
	}, []);

	return (
		<div id="app">
			<Store>
				<Menu isMenuVisible={isMenuVisible} hideMenu={() => setIsMenuVisible(false)} />
				<Router>
					<EditPost path="/post/:postId" />
					<AddPost path="/add" />
					<Banners path="/banners" />
					<Login path="/login" />
					<Links path="/links/:page?" />
					<Home path="/page/:page" />
					<Home path="/" />
				</Router>
				<Toaster />
			</Store>
		</div>
	);
};

export default App;
