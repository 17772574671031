const API_URL = '/api';

export const checkStatus = () => {
  return window.fetch(`${API_URL}/status`, {
    credentials: 'include',
  })
    .then(resp => resp.json());
}

export const login = (username, password) => {
  return window.fetch(`${API_URL}/login`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({
      username, password,
    }),
  })
    .then(resp => resp.json());
}

export const uploadFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return fetch(`${API_URL}/upload`, {
    method: "post",
    credentials: 'include',
    body: formData
  })
    .then(resp => resp.json());
}

export const getPosts = (page) => {
  return window.fetch(`${API_URL}/page/${page}`, {
    credentials: 'include',
  })
    .then(resp => resp.json());
}

export const searchPosts = (query) => {
  return window.fetch(`${API_URL}/search/${query}`, {
    credentials: 'include',
  })
    .then(resp => resp.json());
}

export const editPost = (postData) => {
  return window.fetch(`${API_URL}/edit`, {
    credentials: 'include',
    method: "post",
    body: JSON.stringify(postData),
  })
    .then(resp => resp.json());
}

export const createPost = (postData) => {
  return window.fetch(`${API_URL}/add`, {
    credentials: 'include',
    method: "post",
    body: JSON.stringify(postData),
  })
    .then(resp => resp.json());
}

export const getPost = (postid) => {
  return window.fetch(`${API_URL}/post/${postid}`, {
    credentials: 'include',
  })
    .then(resp => resp.json());
}

export const getLinks = (page) => {
  return window.fetch(`${API_URL}/links/${page}`, {
    credentials: 'include',
  })
    .then(resp => resp.json());
}

export const getShortUrl = (url) => fetch('https://i.mobila.name/?url=' + url)
    .then((resp) => resp.json());
