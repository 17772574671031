import { useEffect, useState } from 'preact/hooks';
import { Link } from 'preact-router/match';
import { useSimpleState } from "preact-store";
import Spinner from '../spinner/spinner';

import { checkStatus } from '../../api';

import style from './layout.css';

const App = (SecuredComponent) => (props) => {
  const [isLoggedIn, setIsLoggedIn] = useSimpleState("isLoggedIn");
  const [username, setUsername] = useSimpleState("username");
  const [loading, setLoading] = useState(!isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      checkStatus()
        .then(({ username }) => {
          setLoading(false);
          setIsLoggedIn(true);
          setUsername(username);
        })
        .catch(() => {
          setLoading(false);
          setIsLoggedIn(false);
        });
    }
  }, []);

  if (loading) {
    return <Spinner text="Загружаю" />;
  }

  if (!isLoggedIn) {
    return <div class={style.loginLink}>
      Пожалуйста <Link href="/login">Войдите</Link>
    </div>
  }

  const clearCookie = () => {
    document.cookie = 'PHPSESSID=';
  }

  return (
    <div class={style.container}>
      <div class={style.header}>
        <div class={style.logo}>
          🦸🏼‍♂️Суперадмин
        </div>
        <div>
          📑
          <Link href='/' activeClassName={style.navActive}>
            Все посты
          </Link>
          {' '}
          -
          {' '}
          🔗
          <Link href='/links' activeClassName={style.navActive}>
            Короткие ссылки
          </Link>
          {' '}
          -
          {' '}
          🌉
          <Link href='/banners' activeClassName={style.navActive}>
            Баннеры
          </Link>
          {' '}
          -
          {' '}
          🔍
          <a href='javascript:void(0);window.showMenu()'>
            Искать
          </a>
          {' '}
          -
          {' '}
          🚪
          <a href='/login' class={style.logout} onClick={clearCookie}>
            Выйти
          </a>
          {' '}
          <small>
            [{username}]
          </small>
        </div>
      </div>
      <SecuredComponent {...props} />
    </div>
  );
};

export default App;
