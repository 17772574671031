import { useState } from 'preact/hooks';
import MarkdownIt from 'markdown-it';
import MdEditor, { Plugins } from 'react-markdown-editor-lite';
import DateTimePicker from 'react-datepicker';
import TokenInput from 'react-customize-token-input';
import toast from 'react-hot-toast';

import style from './post-form.css';

import { uploadFile } from '../../api';

const PostForm = ({ postData, saveHandler }) => {
  const [post, setPost] = useState(postData || {
    title: '',
    text: '',
    preview: '',
    keywords: '',
    header: '',
    active: '0',
    date: Math.floor(new Date().getTime() / 1000),
  });
  const [saving, setSaving] = useState(false);
  const mdParser = new MarkdownIt({ html: true, });

  const onChangehandler = ({ target: { name, value } }) => {
    setPost({
      ...post,
      [name]: value,
    });
  }

  const handleEditorChange = (name) => ({ text }) => {
    setPost({
      ...post,
      [name]: text,
    })
  };

  const onImageUpload = (file) => {
    return uploadFile(file)
      .then(data => data.url);
  }

  const handlePreviewUpload = (e) => {
    e.preventDefault();

    toast.promise(uploadFile(e.target.files[0]), {
      loading: 'Сохраняю',
      success: ({ url }) => {
        setPost({
          ...post,
          header: url,
        }); return 'Файл загружен';
      },
      error: 'Не смог загрузить файл',
    });
  }

  const onTagsChange = (tags) => {
    setPost({
      ...post,
      keywords: tags.join(', '),
    });
  }

  const savePostHandler = (e) => {
    setSaving(true);
    e.preventDefault();

    toast.promise(saveHandler(post), {
      loading: 'Сохраняю',
      success: () => { setSaving(false); return 'Пост сохранен' },
      error: () => { setSaving(false); return 'Не смог сохранить' },
    });
  }

  const renderMd = (name, data) => {
    MdEditor.use(Plugins.AutoResize, {
      min: 200,
    });
    MdEditor.unuse(Plugins.FullScreen);
    MdEditor.unuse(Plugins.Clear);

    return (
      <MdEditor
        key={name}
        id={name}
        renderHTML={text => mdParser.render(text)}
        defaultValue={data}
        onChange={handleEditorChange(name)}
        view={{
          html: false,
        }}
        placeholder="Введите текст"
        onImageUpload={onImageUpload}
      />
    );
  }

  return (
    <div class={style.layout}>
      <div class={style.mainData}>
        <div>
          <label>Заголовок</label>
          <input
            type="text"
            name="title"
            value={post.title}
            onChange={onChangehandler}
            placeholder="Введите заголовок"
          />
        </div>
        <div>
          <label>Превью</label>
          {renderMd('preview', post.preview)}
        </div>
        <div>
          <label>Текст</label>
          {renderMd('text', post.text)}
        </div>
      </div>
      <div class={style.metaData}>
        <div class={style.aside}>
          <div>
            <label>Превью картинка</label>
            {post.header && (
              <img src={post.header} />
            )}
            <input type="file"
              onChange={handlePreviewUpload}
            />
          </div>
          <div>
            <label>Дата публикации</label>
            <DateTimePicker
              selected={post.date && post.date * 1000}
              dateFormat="MMMM d, yyyy h:mm aa"
              showTimeSelect
              onChange={(date) => {
                const value = new Date(date).getTime() / 1000;

                setPost({
                  ...post,
                  date: value,
                });
              }} />
          </div>
          <div>
            <label>Теги</label>
            <TokenInput
              tokenValues={(post.keywords || '').split(', ').filter(t => !!t)}
              onTokenValuesChange={onTagsChange}
            />
          </div>
          <div>
            <label>Статус</label>
            <label>
              <input
                type="checkbox"
                checked={post.active === '1'}
                onChange={({ target: { checked } }) => {
                  setPost({
                    ...post,
                    active: checked ? '1' : '0'
                  })
                }}
              />
              {' '}
              Опубликовать
            </label>
          </div>
          <br />
          <div>
            <button
              disabled={saving}
              onClick={savePostHandler}
            >{saving ? 'Сохраняю' : 'Сохранить'}</button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PostForm;
