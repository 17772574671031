import style from './style.css';

const Spinner = ({ text }) => (
  <div class={style.spinner}>
    <span>↻</span>
    {text && ` ${text}`}
  </div>
);

export default Spinner;
