import Helmet from 'preact-helmet';
import { route } from 'preact-router';

import style from './post.css';

import Auth from '../../components/auth';
import PostForm from '../../components/post-form';
import { createPost } from '../../api';

const AddPost = () => {

  return (
    <div class={style.home}>
      <Helmet
        title={`Создать пост`}
      />
      <h2>
        Создать пост
      </h2>
      <PostForm saveHandler={(d) => {
        return createPost(d)
          .then(resp => {
            route(`/post/${resp.postId}`, true);
            return resp;
          })
      }} />
    </div>
  )
};

export default Auth(AddPost);
